import * as React from "react"
import { useState } from "react"

export function TeamWellM(props) {
  const teamData = [
    {
      name: "Rakesh Sarin",
      designation: "Founder & Chairman",
      slogan: "A joyful heart has tremendous power to heal",
    },

    {
      name: "Divyansh Kumar",
      designation: "Chief Technology Officer",

      slogan: "Be the smile that you want to spread to the world",
    },
    {
      name: "Shivi Sabharwal",
      designation: "Co-Founder & Director",
      slogan:
        "Love Selflessly, Give Joyfully, Speak Consciously, Dance Carelessly, Live Blissfully and Receive Abundantly",
    },
    {
      name: "Hriday Thakur",
      designation: "Executive Founder's Office",
      slogan:
        "Our days are happier when we give people a bit of our heart rather than a piece of our mind",
    },
    {
      name: "Lehar Malhotra",
      designation: "Lead Counselling Psychologist",
      slogan:
        "Passionate about bringing healing and hope to those who need it most.",
    },
    {
      name: "Rohini",
      designation: "Business Partnerships Lead",
      slogan:
        "Believes in the transformative power of self-care and self-compassion.",
    },
    {
      name: "Rohit",
      designation: "Accounts Executive",
      slogan:
        "Committed to creating a world where mental health is a priority, not a stigma.",
    },
    {
      name: "Aditya Sankhala",
      designation: "Lead Software Developer",
      slogan: "The only impossible journey is the one you never begin.",
    },
  ]

  const [member, setMember] = useState(teamData[0])

  function handleHover(index) {
    setMember(teamData[index])
  }
  return (
    <>
      <div className="div-twm">
        <div className="div-twm-2">Meet the Talented team of WellM</div>
        <div className="div-twm-3">
          <div className="div-twm-4 team-members">
            <div className="column-twm">
              <div className="memberName">
                {member["name"]} - {member["designation"]}
              </div>
              {/* <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/363de9a7-a579-4a2d-b9ce-b6f7ba71a1ac?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                className="img-twm"
              /> */}
            </div>
            <div className="column-twm-2">
              <img
                loading="lazy"
                src="/img/rs.jpeg"
                className={member === 0 ? "img-twm-2 selected-photo" : "img-twm-2"}
                onMouseEnter={() => handleHover(0)}
                onMouseLeave={() => handleHover(0)}
                alt=""
              />
            </div>
            <div className="column-twm-3">
              <img
                loading="lazy"
                src="/img/dk.jpeg"
                className={member === 1 ? "img-twm-3 selected-photo" : "img-twm-3"}
                onMouseEnter={() => handleHover(1)}
                onMouseLeave={() => handleHover(0)}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="div-twm-5">
          <div className="div-twm-6">
            <div className="column-twm-4">
              <div className="div-twm-7">
                <div className="div-twm-8">
                  “{member["slogan"]}”
                  <br />
                </div>
                <div className="div-twm-9">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe5cfa09-dea0-404c-8b1a-c4da818b5455?apiKey=a17d9bbf0674442885e9ea114d3a3964&"
                    className="img-twm-4"
                    alt=""
                  />
                  <div className="div-twm-10">Yeah! That’s Us</div>
                </div>
              </div>
            </div>
            <div className="column-twm-5">
              <div className="div-twm-11">
                <div className="div-twm-12">
                  <div className="div-twm-13">
                    <div className="column-twm-6">
                      <div className="div-twm-14">
                        <img
                          loading="lazy"
                          src="/img/shivi.png"
                          className={
                            member !== 2 ? "img-twm-5" : "img-twm-5 selected-photo"
                          }
                          onMouseEnter={() => handleHover(2)}
                          onMouseLeave={() => handleHover(0)}
                          alt=""
                        />
                        <img
                          loading="lazy"
                          src="/img/hriday.png"
                          className="img-twm-6"
                          onMouseEnter={() => handleHover(3)}
                          onMouseLeave={() => handleHover(0)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="column-twm-7">
                      <img
                        loading="lazy"
                        src="/img/lehar.png"
                        className="img-twm-7"
                        onMouseEnter={() => handleHover(4)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="div-twm-15">
                  <div className="div-twm-16">
                    <div className="column-twm-8">
                      <img
                        loading="lazy"
                        className="img-twm-8"
                        src="/img/rohini.png"
                        style={{ width: "186px", marginBottom: "24%" }}
                        onMouseEnter={() => handleHover(5)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                    <div className="column-twm-9">
                      <img
                        loading="lazy"
                        style={{ width: "160px", marginBottom: "24%" }}
                        src="/img/rohit.png"
                        className="img-twm-9"
                        onMouseEnter={() => handleHover(6)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                    <div className="column-twm-9">
                      <img
                        loading="lazy"
                        src="/img/aditya.png"
                        style={{ width: "160px", marginBottom: "24%" }}
                        className="img-twm-9"
                        onMouseEnter={() => handleHover(7)}
                        onMouseLeave={() => handleHover(0)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .memberName {
          background-color: #fae9c8;
          padding: 2%;
          border-radius: 10px;
          font-size: 1.4rem;
          width: 70%;
        }

        .div-twm {
          display: flex;
          flex-direction: column;
          padding: 0 8%;
          margin-top: 6%;
        }
        @media (max-width: 991px) {
          .div-twm {
            display: none;
          }
        }
        .team-members img:hover {
          cursor: pointer;
        }
        .div-twm-2 {
          font-size: 2rem;
          font-weight: 400;
          color: #646464;
        }
        @media (max-width: 991px) {
          .div-twm-2 {
            max-width: 100%;
            font-size: 40px;
          }
        }
        .div-twm-3 {
          align-self: center;
          margin-top: 49px;
          width: 100%;
          max-width: 1328px;
        }
        @media (max-width: 991px) {
          .div-twm-3 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-4 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-4 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 70%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm {
            width: 100%;
          }
        }
        .img-twm {
          aspect-ratio: 5.59;
          object-fit: contain;
          object-position: center;
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          margin-top: 59px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .column-twm-2 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-2 {
            width: 100%;
          }
        }
        .img-twm-2 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 149px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: 10px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm-2 {
            margin-top: 40px;
          }
        }

        .selected-photo {
          border: 2px solid;
        }

        .column-twm-3 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 15%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-3 {
            width: 100%;
          }
        }
        .img-twm-3 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 135px;
          overflow: hidden;
          border-radius: 50%;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-twm-3 {
            margin-top: 40px;
          }
        }
        .img-twm-3:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
        .div-twm-5 {
          align-self: center;

          width: 100%;
          max-width: 1357px;
        }
        @media (max-width: 991px) {
          .div-twm-5 {
            max-width: 100%;
          }
        }
        .div-twm-6 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-6 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-4 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 52%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-4 {
            width: 100%;
          }
        }
        .div-twm-7 {
          display: flex;

          flex-grow: 1;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-twm-7 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-8 {
          color: #646464;
          font-size: 1.4rem;
          font-weight: 300;
        }
        @media (max-width: 991px) {
          .div-twm-8 {
            max-width: 100%;
          }
        }
        .div-twm-9 {
          flex-direction: column;
          overflow: hidden;
          position: relative;
          display: flex;
          aspect-ratio: 0.7407407407407407;
          margin-left: 0;
          width: 340px;
          max-width: 100%;
          flex-grow: 1;
          margin-top: 6%;
        }
        .img-twm-4 {
          position: absolute;
          height: 80%;
          width: 80%;
          object-fit: cover;
          object-position: center;
        }
        .div-twm-10 {
          position: relative;
          text-align: center;
          align-self: end;
          margin-bottom: -70px;
          font-size: 1.4rem;
          color: #646464;
          font-weight: 400;
        }
        @media (max-width: 991px) {
          .div-twm-10 {
            margin-bottom: 10px;
            text-wrap: initial;
          }
        }
        .column-twm-5 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 48%;
          margin-left: 20px;
        }
        @media (max-width: 991px) {
          .column-twm-5 {
            width: 100%;
          }
        }
        .div-twm-11 {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 991px) {
          .div-twm-11 {
            max-width: 100%;
            margin-top: 40px;
          }
        }
        .div-twm-12 {
          align-self: stretch;
        }
        @media (max-width: 991px) {
          .div-twm-12 {
            max-width: 100%;
          }
        }
        .div-twm-13 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-13 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-6 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 69%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-6 {
            width: 100%;
          }
        }
        .div-twm-14 {
          display: flex;
          margin-top: -12px;
          width: 420px;
          max-width: 100%;
          flex-grow: 1;
          align-items: flex-start;
          justify-content: space-between;
          gap: 20px;
        }
        @media (max-width: 991px) {
          .div-twm-14 {
            margin-top: 40px;
          }
        }
        .img-twm-5 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 181px;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        .img-twm-5:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
        .img-twm-6 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 181px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: 45px;
          flex: 1;
        }
        @media (max-width: 991px) {
          .img-twm-6 {
            margin-top: 40px;
          }
        }
        .img-twm-6:hover {
          border: 2px solid;
          cursor: pointer;

          border-radius: 50%;
        }
        .column-twm-7 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 31%;
          margin-left: -12px;
        }
        @media (max-width: 991px) {
          .column-twm-7 {
            width: 100%;
          }
        }
        .img-twm-7 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 181px;
          overflow: hidden;
          border-radius: 50%;
          max-width: 100%;
        }
        @media (max-width: 991px) {
          .img-twm-7 {
            margin-top: 36px;
          }
        }
        .img-twm-7:hover {
          border: 2px solid;
          cursor: pointer;

          border-radius: 50%;
        }
        .div-twm-15 {
          align-self: center;
          width: 461px;
          max-width: 100%;
          margin: 17px 0 0 13px;
        }
        .div-twm-16 {
          gap: 20px;
          display: flex;
        }
        @media (max-width: 991px) {
          .div-twm-16 {
            flex-direction: column;
            align-items: stretch;
            gap: 0px;
          }
        }
        .column-twm-8 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: 0px;
        }
        @media (max-width: 991px) {
          .column-twm-8 {
            width: 100%;
          }
        }
        .img-twm-8 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 188px;
          overflow: hidden;
          border-radius: 50%;
          margin-top: -39px;
          flex-grow: 1;
        }
        @media (max-width: 991px) {
          .img-twm-8 {
            margin-top: 40px;
          }
        }

        .img-twm-8:hover {
          border: 2px solid;
          border-radius: 50%;

          cursor: pointer;
        }
        .column-twm-9 {
          display: flex;
          flex-direction: column;
          line-height: normal;
          width: 50%;
          margin-left: -6px;
        }
        @media (max-width: 991px) {
          .column-twm-9 {
            width: 100%;
          }
        }
        .img-twm-9 {
          aspect-ratio: 1.03;
          object-fit: contain;
          object-position: center;
          width: 139px;
          overflow: hidden;
          border-radius: 50%;
          flex: 1;
        }
        @media (max-width: 991px) {
          .img-twm-9 {
            margin-top: 40px;
          }
        }
        .img-twm-9:hover {
          border: 2px solid;
          border-radius: 50%;
          cursor: pointer;
        }
      `}</style>
    </>
  )
}
